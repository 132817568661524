export class Translations {
    static Add = "Add";
    static AddUseCase = "Add Use Case";
    static AddInTitle = "Aiddocs Excel Add-in";
    static AddinServiceUrl = "Add-in Service Url";
    static AddinServiceUrlMustBeDefined = "Add-in service url must be defined!";
    static AdditionalActions = "Additional Actions";
    static Aiddocs = "Aiddocs";
    static AiddocsCallContext = "Aiddocs Call Context";
    static AiddocsOwnerCode = "Aiddocs Owner Code";
    static AiddocsOwnerPassword = "Aiddocs Owner Password";
    static AiddocsOwnerUsername = "Aiddocs Owner Username";
    static AiddocsServiceUrl = "Aiddocs Service Url";
    static AiddocsOwnerCodeMustBeDefined = "Aiddocs owner code must be defined!";
    static AiddocsOwnerPasswordMustBeDefined = "Aiddocs owner password must be defined!";
    static AiddocsOwnerUsernameMustBeDefined = "Aiddocs owner username must be defined!";
    static AiddocsServiceUrlMustBeDefined = "Aiddocs service url must be defined!";
    static All = "All";
    static AllowMultiple = "Allow Multiple File Generation";
    static ApplicationSettings = "Application Settings";
    static AutoOpenAddinWhenDocumentIsOpened = "Automatically open addin when document is opened";
    static Basic = "Basic";
    static BrowseXlsx = "Browse Xlsx";
    static Cancel = "Cancel";
    static ChangeOwner = "Change Owner";
    static Close = "Close";
    static Code = "Code";
    static CodeIsRequired = "Code is required!";
    static Comment = "Comment";
    static ConditionDoesNotExist = "Condition does not exist!";
    static Copy = "Copy";
    static CopyTemplate = "Copy Template";
    static CreateMode = "Create Mode";
    static Data = "Data";
    static DataControls = "Data Controls";
    static DataSetName = "Data Set Name";
    static DataSetDoesNotContainData = "Data set does not contain data!";
    static Description = "Description";
    static DocumentGeneration = "Document Generation";
    static Download = "Download";
    static Edit = "Edit";
    static EditTemplate = "Edit Template";
    static EditUseCase = "Edit Use Case";
    static Error = "Error";
    static ExcelFile = "Excel File";
    static ExcelFileUpdated = "Excel file updated";
    static Format = "Format";
    static FormDataModifiedConfirmation = "Form data has been changed, do you want to save changes?";
    static GeneratedXMLdata = "Generated XML data";
    static GetData = "Get Data";
    static Hide = "Hide";
    static HideHidden = "Hide Hidden";
    static HideParameterData = "Hide Parameter Data";
    static Home = "Home";
    static Language = "Language";
    static LanguageFinnish = "Finnish";
    static LanguageEnglish = "English";
    static LanguageSwedish = "Swedish";
    static Loading = "Loading...";
    static Login = "Login";
    static Logout = "Logout";
    static ManagementMode = "Management Mode";    
    static More = "More";    
    static Name = "Name";
    static NameIsRequired = "Name is required!";
    static NextPage = "Next Page";
    static NewDocument = "New Document";
    static NewUseCase = "New Use Case";
    static NewTemplateCode = "New Template Code";
    static NewTemplateName = "New Template Name";
    static No = "No";
    static NoPublishedTemplatesFound = "No published templates found!";
    static NoTemplatesGroupsFound = "No template groups found!";
    static NotInUse = "Not in use";
    static Of = "of";
    static OmitElementsWithPrefix = "Omit Elements with Prefix";
    static Open = "Open";
    static OpenSampleDataFile = "Open datafile from your own computer or download one of the samples";
    static OutputFormat = "Output Format";
    static Owner = "Owner";
    static ParameterData = "Parameter Data";
    static ParameterDataCopiedToClipboard = "Parameter data copied to clipboard";
    static ParameterDataCopyingToClipboardFailed = "Failed to copy parameter data to clipboard!";
    static ParameterDataGeneration = "Parameter Data Generation";
    static ParameterDataReadingFailed = "Failed to read parameter data from excel!";
    static ParameterDataValidationFailed = "Failed to validate xml parameter data!";
    static Parts = "Parts";
    static Password = "Password";
    static PasswordIsRequired = "Password is required!";
    static Placeholders = "Placeholders";
    static Preview = "Preview";
    static PreviousPage = "Previous Page";
    static Properties = "Properties";
    static ReadingExcelFailed = "Failed to read excel contents!";
    static Resource = "Resource";
    static Refresh = "Refresh";
    static RefreshList = "Refresh List";
    static RefreshPreview = "Refresh Preview";
    static ReturnToUseCase = "Return to Use Case";
    static Remove = "Remove";
    static RemoveTemplate = "Remove Template";
    static RemoveUseCase = "Remove Use Case";
    static Removing = "Removing...";
    static RootElementName = "Root Element Name";
    static RootElementNameIsInvalid = "Root element name is invalid!";
    static Save = "Save";
    static SaveExcelFileContent = "Save Excel File Content";
    static Saving = "Saving...";
    static SelectImageFile = "Select image file!";
    static SelectOwner = "Select Owner";
    static SelectTemplate = "Select Template";
    static SelectTemplateForPreview = "Select template for preview!";
    static Settings = "Settings";
    static Show = "Show";
    static ShowHidden = "Show Hidden";
    static ShowParameters = "Show Parameters";
    static ShowPreview = "Show Preview";
    static SortOrder = "Sort Order";
    static State = "State";
    static Structure = "Structure";
    static Table = "Table";
    static TableNotFound = "Table not found!";
    static Template = "Template";
    static TemplateCodeInvalid = "Template code contains illegal code characters (' ', \, ?, /, ^, &, +, <, > or :) or the value ends with '.'!";
    static Templates = "Templates";
    static TemplateRemoveConfirmation = "Do you want to remove template '{0}' permanently?";
    static TemplateGroup = "Template Group";
    static Thumbnail = "Thumbnail";
    static ThumbnailIsNotAvailable = "Thumbnail is not available!";
    static ToolTip = "Tooltip";
    static UnnamedGroup = "Unnamed group";
    static Upload = "Upload";
    static UploadCurrentlyOpenExcel = "Upload currently open excel";
    static UseCase = "Use Case";
    static UseCases = "Use Cases";
    static UseCaseData = "Use Case Data";
    static UseCaseExcelFileSaveFailed = "Use case excel file save failed!";
    static UseCaseRemoveConfirmation = "Do you want to remove use case '{0}' permanently?";
    static UseCollectionElements = "Use Collection Elements";
    static Username = "Username";
    static UsernameIsRequired = "Username is required!";
    static ViewData = "View Data";
    static Warning = "Warning";
    static Yes = "Yes";
}