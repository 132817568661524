import { UseCaseTemplateGroupsList } from "../useCase";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { RootState, useAppDispatch } from "../../store";
import { ITemplateGroup, TemplateGroup } from "../../models/templateGroup/templateGroup";
import { getTemplateGroups, getThumbnail } from "../../models/services/templateService";
import { LanguageCode, TemplateStateCode, TemplateThumbnailSize } from "../../models/common/enums";
import { Translations } from "../../models/common/translations";
import { appActions } from "../../store/app";
import { useSelector } from "react-redux";
import { templateActions } from "../../store/template";
import { TemplateDetailCard } from "./TemplateDetailCard";

//Component
    export const TemplateGroups = ({tabKey}) => {
    const mounted = useRef(false);
    const dispatch = useAppDispatch();
    let { id } = useParams();
    const authUser = useSelector((state: RootState) => state.auth.authUser);
    const [filteredTemplateGroups, setFilteredTemplateGroups] = useState<ITemplateGroup[]>([]);
    const [templateGroups, setTemplateGroups] = useState<ITemplateGroup[]>([]);
    const templateListId = useSelector((state: RootState) => state.template.templateListId);
    const showArchived = useSelector((state: RootState) => state.template.showArchived);
    const template = useSelector((state: RootState) => state.template.template);
    const returnUrl = `/${tabKey}`;

    const loadThumbnail = async (oldTemplateGroups: ITemplateGroup[], templateGroupCode: string, templateCode: string) => {
        try {
            const thumnailResult = await getThumbnail(templateCode, TemplateThumbnailSize.Medium);
            const templateGroup = oldTemplateGroups.find(i => i.code === templateGroupCode);
            if (!templateGroup) return;
            const template = templateGroup.templates.find(i => i.code === templateCode);
            if (!template) return;
            if (thumnailResult.thumbnail) {
                template.thumbnail = thumnailResult.thumbnail;
            } else {
                template.thumbnailText = Translations.ThumbnailIsNotAvailable;
            }
            if (!mounted) return;
            setTemplateGroups([...oldTemplateGroups]);
        } catch (error) {
            console.log("loadThumbnail", error);
        }
    }

    const modifyFilteredTemplateGroups = () => {
        const result: ITemplateGroup[] = [];
        for (const templateGroup of templateGroups) {
            const templates = templateGroup.templates.filter(i => showArchived || i.stateCode !== TemplateStateCode.Archived);
            if (!templates.length) continue;
            const newTemplateGroup = new TemplateGroup();
            newTemplateGroup.code = templateGroup.code;
            newTemplateGroup.name = templateGroup.name;
            newTemplateGroup.templates = templates;
            result.push(newTemplateGroup);
        }
        setFilteredTemplateGroups(result);
    }

    useEffect(() => {
        modifyFilteredTemplateGroups();
    }, [templateGroups, showArchived]);

    const loadTemplateGroups = async () => {
        dispatch(appActions.showSpinner());
        try {
            const newTemplateGroups = await getTemplateGroups(LanguageCode.Fi, "");
            if (!mounted || !newTemplateGroups) return;
            setTemplateGroups(newTemplateGroups.items);
            setFilteredTemplateGroups(newTemplateGroups.items);
            if (newTemplateGroups.items && newTemplateGroups.items[0].templates) {
                dispatch(templateActions.setTemplate(newTemplateGroups.items[0].templates[0]));
            }
            for (const templateGroup of newTemplateGroups.items) {
                for (const template of templateGroup.templates) {
                    if (!mounted) break;
                    await loadThumbnail(newTemplateGroups.items, templateGroup.code, template.code);
                }
            }
        } catch (error) {
            console.log("loadTemplateGroups", error);
            dispatch(appActions.showError(error.toString()));
        } finally {
            dispatch(appActions.hideSpinner());
        }
    }

    const loadData = async () => {
        loadTemplateGroups();
    }

    useEffect(() => {
        loadData();
    }, [authUser.ownerCode, templateListId]);

    return (
        <div>
            <UseCaseTemplateGroupsList
                useCaseId={id}
                templateGroups={filteredTemplateGroups}
                height={"calc(100vh - " + (450).toString(10) + "px)"}
            />
            {template &&
            <TemplateDetailCard
                returnUrl={returnUrl}
                template={template} />
            }
        </div>
    );
}

export default TemplateGroups;
